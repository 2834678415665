<script context="module" lang="ts">
	import { fade, fly } from 'svelte/transition';
	import ModelViewer from './ModelViewer.svelte';
	import '@leoncvlt/ar-button';
	import { activateAR } from '@leoncvlt/ar-button';
	import jQuery from 'jquery';

	// @ts-check
	export enum STANDS {
		NOSTAND = 0,
		WITHSTAND = 1
	}

	export enum STATES {
		NOCONTROLLER = 0,
		XBOX = 1,
		PLAYSTATION = 2,
		MOBILE = 3
	}

	export let isActive = true;

	export let models = {
		QN90C: {
			type: 'TV',
			size: {
				SIZE75: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90C/QN90C_75inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90C/QN90C_75inch_No_Stand'
				},
				SIZE85: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90C/QN90C_85inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90C/QN90C_85inch_No_Stand'
				},
				SIZE98: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90C/QN90C_98inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90C/QN90C_98inch_No_Stand'
				}
			}
		},
		S95C: {
			type: 'TV',
			size: {
				SIZE65: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S95C/S95C_65inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S95C/S95C_65inch_No_Stand'
				},
				SIZE77: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S95C/S95C_77inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S95C/S95C_77inch_No_Stand'
				}
			}
		},
		QN95C: {
			type: 'TV',
			size: {
				SIZE75: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN95C/QN95C_75inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN95C/QN95C_75inch_No_Stand'
				},
				SIZE85: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN95C/QN95C_85inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN95C/QN95C_85inch_No_Stand'
				}
			}
		},
		Q80C: {
			type: 'TV',
			size: {
				SIZE65: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Q80C/Q80C_65inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Q80C/Q80C_65inch_No_Stand'
				},
				SIZE75: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Q80C/Q80C_75inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Q80C/Q80C_75inch_No_Stand'
				},
				SIZE85: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Q80C/Q80C_85inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Q80C/Q80C_85inch_No_Stand'
				}
			}
		},
		S90C: {
			type: 'TV',
			size: {
				SIZE55: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90C/S90C_55inch_v3',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90C/S90C_55inch_No_Stand_v3'
				},
				SIZE65: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90C/S90C_65inch_v4',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90C/S90C_65inch_No_Stand_v4'
				}
			}
		},
		FRAME: {
			type: 'TV',
			size: {
				SIZE32: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Frame/The_Frame_32inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Frame/The_Frame_32inch_No_Stand'
				},
				SIZE43: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Frame/The_Frame_43inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Frame/The_Frame_43inch_No_Stand'
				},
				SIZE55: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Frame/The_Frame_55inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Frame/The_Frame_55inch_No_Stand'
				},
				SIZE65: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Frame/The_Frame_65inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Frame/The_Frame_65inch_No_Stand'
				},
				SIZE75: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Frame/The_Frame_75inch',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Frame/The_Frame_75inch_No_Stand'
				}
			}
		},
		LS01D: {
			type: 'TV',
			size: {
				SIZE43: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS01D/LS01D_43Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS01D/LS01D_43Inch_NoStand'
				},
				SIZE50: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS01D/LS01D_50Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS01D/LS01D_50Inch_NoStand'
				},
				SIZE55: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS01D/LS01D_55Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS01D/LS01D_55Inch_NoStand'
				},
				SIZE65: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS01D/LS01D_65Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS01D/LS01D_65Inch_NoStand'
				}
			}
		},
		LS03D_Frame: {
			type: 'TV',
			size: {
				SIZE43: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_43Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_43Inch_NoStand'
				},
				SIZE50: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_50Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_50Inch_NoStand'
				},
				SIZE55: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_55Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_55Inch_NoStand'
				},
				SIZE65: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_65Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_65Inch_NoStand'
				},
				SIZE75: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_75Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_75Inch_NoStand'
				},
				SIZE85: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_85Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS03D_Frame/LS03D_Frame_85Inch_NoStand'
				}
			}
		},
		LS60D_Music_Frame: {
			type: 'TV',
			size: {
				SIZE43: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/LS60D_Music_Frame/LS60D_Music_Frame'
				}
			}
		},
		Q800D_Soundbar: {
			type: 'SOUNDBAR',
			size: {
				SIZE43: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/Q800D_Soundbar/Q800D_Soundbar'
				}
			}
		},
		QN90D: {
			type: 'TV',
			size: {
				SIZE43: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_43Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_43Inch_NoStand'
				},
				SIZE50: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_50Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_50Inch_NoStand'
				},
				SIZE55: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_55Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_55Inch_NoStand'
				},
				SIZE65: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_65Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_65Inch_NoStand'
				},
				SIZE75: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_75Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_75Inch_NoStand'
				},
				SIZE85: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_85Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_85Inch_NoStand'
				},
				SIZE98: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_98Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN90D/QN90D_98Inch_NoStand'
				}
			}
		},
		QN900D: {
			type: 'TV',
			size: {
				SIZE65: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN900D/QN900D_65Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN900D/QN900D_65Inch_NoStand'
				},
				SIZE75: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN900D/QN900D_75Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN900D/QN900D_75Inch_NoStand'
				},
				SIZE85: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN900D/QN900D_85Inch_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/QN900D/QN900D_85Inch_NoStand'
				}
			}
		},
		S90D: {
			type: 'TV',
			size: {
				SIZE48: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90D/S90D_48Inches_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90D/S90D_48Inches_NoStand'
				},
				SIZE55: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90D/S90D_55Inches_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90D/S90D_55Inches_NoStand'
				},
				SIZE65: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90D/S90D_65Inches_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90D/S90D_65Inches_NoStand'
				},
				SIZE77: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90D/S90D_77Inches_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90D/S90D_77Inches_NoStand'
				},
				SIZE83: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90D/S90D_83Inches_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S90D/S90D_83Inches_NoStand'
				}
			}
		},
		S95D: {
			type: 'TV',
			size: {
				SIZE55: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S95D/S95D_55Inches_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S95D/S95D_55Inches_NoStand'
				},
				SIZE65: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S95D/S95D_65Inches_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S95D/S95D_65Inches_NoStand'
				},
				SIZE77: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S95D/S95D_77Inches_Stand',
					WALL: 'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S95D/S95D_77Inches_NoStand'
				}
			}
		},
		S700D_Soundbar: {
			type: 'SOUNDBAR',
			size: {
				SIZE43: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S700D_Soundbar/S700D_Soundbar'
				}
			}
		},
		S800D_Soundbar: {
			type: 'SOUNDBAR',
			size: {
				SIZE43: {
					STAND:
						'https://samsungartvxpstorage.z33.web.core.windows.net/assets/samsung/S800D_Soundbar/S800D_Soundbar'
				}
			}
		}
	};
</script>

<script lang="ts">
	// @ts-check
	import { createEventDispatcher } from 'svelte';
	import { select_value } from 'svelte/internal';

	const dispatch = createEventDispatcher();

	const urlParams = new URLSearchParams(window.location.search);
	const tvmodel = urlParams.get('sku') || urlParams.get('model');
	const tvsize = urlParams.get('size');

	const tvPlacement = urlParams.get('placement');

	console.log(tvmodel);
	console.log(tvsize);

	const modelkey = Object.keys(models);
	//let currentModel = modelkey[0];
	let currentModel;
	let currentSize;
	let imgkey;
	let newimglink;
	let modeltype;

	let isChecked = false;

	if (tvPlacement !== null && tvPlacement != '') {
		switch (tvPlacement) {
			case 'wall':
				isChecked = true;
				break;
			case 'stand':
				isChecked = false;
				break;
			default:
				break;
		}
		handleModel(tvPlacement);
	} else {
	}

	$: if (isChecked) {
		handleStand(1); // Call handleStand for the 'WALL' option
	} else {
		handleStand(0); // Call handleStand for the 'STAND' option
	}

	if (tvmodel != null && tvmodel != '') {
		currentModel = tvmodel;
	} else {
		alert('Sorry, Model value is empty.');
	}

	if (tvsize != null && tvsize != '') {
		currentSize = 'SIZE' + tvsize;
	} else {
		currentSize = Object.keys(models[currentModel].size)[0];
	}

	if (modelkey.indexOf(currentModel) < 0) {
		alert('Sorry, This model does not exit.');
		currentModel = modelkey[0];
	}

	let sizekey = Object.keys(models[currentModel].size);
	if (sizekey.indexOf(currentSize) < 0) {
		alert('Sorry, This size does not exit.');
		currentSize = Object.keys(models[currentModel].size)[0];
	}

	//let currentSize:any = Object.keys(models[currentModel].size)[0];
	let imglink = Object.values(models[currentModel].size[currentSize])[0];
	let currentStand: any = Object.keys(models[currentModel].size[currentSize])[0];
	const standkey = Object.keys(models[currentModel].size[currentSize]);
	let place = 'floor';
	console.log('standkey', standkey);

	function handleModel(newModel: string) {
		if (newModel !== currentModel) {
			currentModel = newModel;

			if (models[currentModel]) {
				imgkey = Object.keys(models[currentModel].size)[0];

				newimglink = Object.values(models[currentModel].size[imgkey])[0];
				sizekey = Object.keys(models[currentModel].size);
				currentSize = Object.keys(models[currentModel].size)[0];

				if (newimglink !== imglink) {
					imglink = newimglink;
				}
			}
		}
		// else {
		// 	currentModel = modelkey[0];
		// }
		dispatch('controllerSelected', {
			state: currentModel
		});
	}

	function handleSize(newSize: any) {
		switch (currentModel) {
			case 'QN90C':
				currentSize = sizekey;
				break;
			case 'S95C':
				currentSize = sizekey;
				break;
			case 'QN95C':
				currentSize = sizekey;
				break;
			case 'Q80C':
				currentSize = sizekey;
				break;
			case 'S90C':
				currentSize = sizekey;
				break;
			case 'FRAME':
				currentSize = sizekey;
				break;
			case 'LS01D':
				currentSize = sizekey;
				break;
			case 'LS03D_Frame':
				currentSize = sizekey;
				break;
			case 'LS60D_Music_Frame':
				currentSize = sizekey;
				break;
			case 'Q800D_Soundbar':
				currentSize = sizekey;
				break;
			case 'QN90D':
				currentSize = sizekey;
				break;
			case 'QN900D':
				currentSize = sizekey;
				break;
			case 'S90D':
				currentSize = sizekey;
				break;
			case 'S95D':
				currentSize = sizekey;
				break;
			case 'S700D_Soundbar':
				currentSize = sizekey;
				break;
			case 'S800D_Soundbar':
				currentSize = sizekey;
				break;
			default:
				return false;
		}
		currentSize.map((k, index) => {
			if (newSize === index) {
				currentSize = k;
				//currentStand = Object.keys(models[currentModel].size[currentSize])[0];

				if (jQuery('#switch').is(':checked')) {
					imglink = Object.values(models[currentModel].size[currentSize])[1];
				} else {
					imglink = Object.values(models[currentModel].size[currentSize])[0];
				}

				console.log(currentSize, imglink);
			}
		});

		dispatch('controllerSelected', {
			state: currentSize
		});
	}

	function handleStand(newStand: number) {
		console.log('function called');
		switch (currentModel) {
			case 'QN90C':
				switch (currentSize) {
					case 'SIZE75':
						currentStand = standkey;
						break;
					case 'SIZE85':
						currentStand = standkey;
						break;
					case 'SIZE98':
						currentStand = standkey;
						break;
				}
				break;
			case 'S95C':
				switch (currentSize) {
					case 'SIZE65':
						currentStand = standkey;
						break;
					case 'SIZE77':
						currentStand = standkey;
						break;
				}
				break;
			case 'QN95C':
				switch (currentSize) {
					case 'SIZE75':
						currentStand = standkey;
						break;
					case 'SIZE85':
						currentStand = standkey;
						break;
				}
				break;
			case 'Q80C':
				switch (currentSize) {
					case 'SIZE65':
						currentStand = standkey;
						break;
					case 'SIZE75':
						currentStand = standkey;
						break;
					case 'SIZE85':
						currentStand = standkey;
						break;
				}
				break;
			case 'S90C':
				switch (currentSize) {
					case 'SIZE55':
						currentStand = standkey;
						break;
					case 'SIZE65':
						currentStand = standkey;
						break;
				}
				break;
			case 'FRAME':
				switch (currentSize) {
					case 'SIZE32':
						currentStand = standkey;
						break;
					case 'SIZE43':
						currentStand = standkey;
						break;
					case 'SIZE50':
						currentStand = standkey;
						break;
					case 'SIZE55':
						currentStand = standkey;
						break;
					case 'SIZE65':
						currentStand = standkey;
						break;
					case 'SIZE75':
						currentStand = standkey;
						break;
				}
				break;
			case 'LS01D':
				switch (currentSize) {
					case 'SIZE43':
						currentStand = standkey;
						break;
					case 'SIZE50':
						currentStand = standkey;
						break;
					case 'SIZE55':
						currentStand = standkey;
						break;
					case 'SIZE65':
						currentStand = standkey;
						break;
				}
				break;
			case 'LS03D_Frame':
				switch (currentSize) {
					case 'SIZE43':
						currentStand = standkey;
						break;
					case 'SIZE50':
						currentStand = standkey;
						break;
					case 'SIZE55':
						currentStand = standkey;
						break;
					case 'SIZE65':
						currentStand = standkey;
						break;
					case 'SIZE75':
						currentStand = standkey;
						break;
					case 'SIZE85':
						currentStand = standkey;
						break;
				}
				break;
			case 'QN90D':
				switch (currentSize) {
					case 'SIZE43':
						currentStand = standkey;
						break;
					case 'SIZE50':
						currentStand = standkey;
						break;
					case 'SIZE55':
						currentStand = standkey;
						break;
					case 'SIZE65':
						currentStand = standkey;
						break;
					case 'SIZE75':
						currentStand = standkey;
						break;
					case 'SIZE85':
						currentStand = standkey;
						break;
					case 'SIZE98':
						currentStand = standkey;
						break;
				}
				break;
			case 'QN90D':
				switch (currentSize) {
					case 'SIZE65':
						currentStand = standkey;
						break;
					case 'SIZE75':
						currentStand = standkey;
						break;
					case 'SIZE85':
						currentStand = standkey;
						break;
				}
				break;
			case 'S90D':
				switch (currentSize) {
					case 'SIZE48':
						currentStand = standkey;
						break;
					case 'SIZE55':
						currentStand = standkey;
						break;
					case 'SIZE65':
						currentStand = standkey;
						break;
					case 'SIZE77':
						currentStand = standkey;
						break;
					case 'SIZE83':
						currentStand = standkey;
						break;
				}
			case 'S95D':
				switch (currentSize) {
					case 'SIZE55':
						currentStand = standkey;
						break;
					case 'SIZE65':
						currentStand = standkey;
						break;
					case 'SIZE77':
						currentStand = standkey;
						break;
				}
				break;
			// default:
			// 	return false;
		}
		// currentStand?.map((k, index) => {
		// 	//console.log(k,index);
		// 	if (newStand === index) {
		// 		currentStand = k;
		// 		imglink = Object.values(models[currentModel].size[currentSize])[index];

		// 		console.log('stand', currentStand);

		// 		if (index == 0) {
		// 			place = 'floor';
		// 		} else {
		// 			place = 'wall';
		// 		}
		// 	}
		// });

		// dispatch('controllerSelected', {
		// 	state: currentStand
		// });

		console.log('Stand Key Array:', standkey);
		console.log('Selected Stand Index:', newStand);

		if (newStand === 0) {
			imglink = models[currentModel].size[currentSize].STAND; // For Stand
			place = 'floor';
		} else if (newStand === 1) {
			imglink = models[currentModel].size[currentSize].WALL; // For Wall
			place = 'wall';
		}

		console.log('Updated Image Link:', imglink);

		// Dispatch event to notify parent components
		dispatch('controllerSelected', { state: currentStand });

		// if (jQuery('#switch').is(':checked')) {
		// 	jQuery('.stand-option .names')
		// 		.find('p:nth-child(2)')
		// 		.css({ color: '#000', 'font-weight': 'normal' });
		// 	jQuery('.stand-option .names')
		// 		.find('p:nth-child(1)')
		// 		.css({ color: '#4c12a1', 'font-weight': 'bold' });
		// } else {
		// 	jQuery('.stand-option .names')
		// 		.find('p:nth-child(1)')
		// 		.css({ color: '#000', 'font-weight': 'normal' });
		// 	jQuery('.stand-option .names')
		// 		.find('p:nth-child(2)')
		// 		.css({ color: '#4c12a1', 'font-weight': 'bold' });
		// }
	}

	function showdiv() {
		jQuery('#standoption').hide();
	}

	function showdiv1() {
		jQuery('#sizeoption').hide();
	}

	let isActivemodel = false;
	function toggleActivemodel() {
		isActivemodel = !isActivemodel;
	}

	let isActivesize = false;
	function toggleActiveSize() {
		isActivesize = !isActivesize;
	}

	let isActivestand = false;
	function toggleActiveStand() {
		isActivestand = !isActivestand;
	}

	function activesize(e) {
		jQuery('.size-option-container')
			.find('button')
			.css({ outline: '0px solid #4c12a1', padding: '0px', 'outline-offset': '0px' });
		if (jQuery('.size-option-container').find('SIZE' + e)) {
			jQuery('button.SIZE' + e).css({
				outline: '2px solid #4c12a1',
				padding: '2px',
				'outline-offset': '2px'
			});
		}
	}

	jQuery(document).ready(function () {
		var classNameVariable = currentSize;
		var matchingsize = jQuery('button.' + classNameVariable);
		matchingsize.css({ outline: '2px solid #4c12a1', padding: '2px', 'outline-offset': '2px' });
	});
</script>

<div class="controller_selector_option">
	{#if currentModel && models[currentModel].type === 'TV'}
		<div class="size-option">
			{#each Object.entries(sizekey) as [key, value], index (key)}
				<div class="size-option-container">
					<button
						class={'size-circle ' + value}
						on:click={() => {
							handleSize(sizekey.indexOf(value));
							activesize(value.slice(-2));
						}}
					>
						<span class="d-none">{value.slice(-2)}"</span>
					</button>
					<span class="size-option-label text-capitalize">{value.slice(-2)}"</span>
				</div>
			{/each}
		</div>
	{/if}

	<hr class="hr-tag" />

	<div class="stand-ar-div">
		{#if currentModel && models[currentModel].type === 'TV'}
			<div class="stand-option" style="flex:1;">
				<input type="checkbox" id="switch" bind:checked={isChecked} />
				<div class="content">
					<label for="switch">
						<div class="toggle" />
						<div class="names">
							<!-- {#each Object.entries(standkey) as [key, value], index (key)}
								<p on:click={() => handleStand(standkey.indexOf(value))}>{value}</p>
							{/each} -->
							<p
								style="color: {!isChecked ? '#4c12a1' : '#000'}; font-weight: {!isChecked
									? 'bold'
									: 'normal'}"
							>
								STAND
							</p>
							<p
								style="color: {isChecked ? '#4c12a1' : '#000'}; font-weight: {isChecked
									? 'bold'
									: 'normal'}"
							>
								WALL
							</p>
						</div>
					</label>
				</div>
			</div>
		{/if}
		<div class="ar-button" style="width: 40%;">
			<a
				id="samsung"
				on:click={() =>
					activateAR({
						src: imglink + '.glb',
						iosSrc: imglink + '.usdz',
						title: 'A 3D model of an Samsung TV'
					})}
			>
				<a
					id="default-ar-button"
					part="default-ar-button"
					class="fab"
					tabindex="2"
					aria-label="View in your space"
				>
					<svg
						version="1.1"
						id="view_x5F_in_x5F_AR_x5F_icon"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						x="0px"
						y="0px"
						width="24px"
						height="24px"
						viewBox="0 0 24 24"
						enable-background="new 0 0 24 24"
						xml:space="preserve"
					>
						<rect id="Bounding_Box" x="0" y="0" fill="none" width="24" height="24" />
						<g id="Art_layer">
							<path d="M3,4c0-0.55,0.45-1,1-1h2V1H4C2.35,1,1,2.35,1,4v2h2V4z" />
							<path d="M20,3c0.55,0,1,0.45,1,1v2h2V4c0-1.65-1.35-3-3-3h-2v2H20z" />
							<path d="M4,21c-0.55,0-1-0.45-1-1v-2H1v2c0,1.65,1.35,3,3,3h2v-2H4z" />
							<path d="M20,21c0.55,0,1-0.45,1-1v-2h2v2c0,1.65-1.35,3-3,3h-2v-2H20z" />
							<g>
								<path
									d="M18.25,7.6l-5.5-3.18c-0.46-0.27-1.04-0.27-1.5,0L5.75,7.6C5.29,7.87,5,8.36,5,8.9v6.35c0,0.54,0.29,1.03,0.75,1.3
										l5.5,3.18c0.46,0.27,1.04,0.27,1.5,0l5.5-3.18c0.46-0.27,0.75-0.76,0.75-1.3V8.9C19,8.36,18.71,7.87,18.25,7.6z M7,14.96v-4.62
										l4,2.32v4.61L7,14.96z M12,10.93L8,8.61l4-2.31l4,2.31L12,10.93z M13,17.27v-4.61l4-2.32v4.62L13,17.27z"
								/>
							</g>
						</g>
					</svg>
				</a>
			</a>
		</div>

		<!-- <button style="margin-right: 30px;" on:click={ () => activateAR({
			src: "https://testing.isitetv.com/EdSouthgate/cable-guys/sonic/assets/Sonic_Phone_AR.glb",
			iosSrc: "https://github.com/leoncvlt/ar-button/raw/master/assets/Astronaut.usdz",
			link: "https://www.nasa.gov/",
			title: "A 3D model of an astronaut"
		})}>SONIC</button> -->
	</div>
</div>

<div>
	<!-- currentmodel: {currentModel}<br> -->
	<!-- currentsize: {currentSize}<br>
	currentstand: {currentStand}<br>
	currentimg: {imglink}<br> -->
</div>

<div>
	<ModelViewer id="ar-model" src="{imglink}.glb" iosSrc="{imglink}.usdz" placement={place} />
</div>

<style type="text/postcss">
	.controller-selector__button {
		@apply bg-no-repeat bg-center bg-cover m-4 lg:w-40 lg:h-40 w-24 h-24 rounded-full overflow-hidden border-none;
	}
	.controller_selector_option {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.size-option {
		text-align: center;
		margin-bottom: 2rem;
		width: 100%;
	}
	.size-option .d-none {
		display: none;
	}
	.size-option .size-option-container {
		display: -ms-inline-flexbox;
		display: inline-flex;
		-ms-flex-direction: column;
		flex-direction: column;
		margin: 0 8px 0 0;
		padding-top: 12px;
		width: 64px;
		position: relative;
		cursor: pointer;
	}
	.size-option .size-option-container .size-option-label {
		font-family: Helvetica, Arial, Sans-Serif;
		line-height: 21px;
		font-size: 14px;
		color: #213038;
		margin-top: 4px;
		display: inline-block;
		text-align: center;
		word-break: break-word;
		font-weight: bold;
	}
	.size-option .size-option-container .size-circle {
		width: 52px;
		height: 52px;
		margin: auto;
		border: 1px solid #cdd8df;
		display: inline-block;
		border-radius: 50%;
		background-image: url(https://currysprod.a.bigcontent.io/v1/static/tv-svg);
		background-repeat: no-repeat;
		background-position: center center;
	}
	.size-option .size-option-container .size-circle.selected {
		outline: 2px solid #4c12a1;
		padding: 2px;
		outline-offset: 2px;
	}
	.hr-tag {
		border: 1px solid #cdd8df;
		width: 100%;
	}
	.stand-ar-div {
		height: 100%;
		display: flex;
		padding: 2rem 0;
		width: 100%;
		justify-content: right;
	}
	.stand-option {
		height: 50px;
	}
	.stand-option .content {
		display: flex;
		flex-direction: column;
		text-align: center;
		width: 100%;
		height: 100%;
	}
	.stand-option label,
	.stand-option .toggle {
		height: 2.8rem;
		border-radius: 100px;
		line-height: 2.8rem;
	}
	.stand-option label {
		width: 61%;
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 100px;
		position: relative;
		margin: 1.8rem 0 4rem 0;
		cursor: pointer;
		margin: auto;
		height: 100%;
	}
	.stand-option .toggle {
		position: absolute;
		width: 50%;
		background-color: #fff;
		border: 2px solid #4c12a1;
		color: #4c12a1;
		transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		height: 100%;
	}
	.stand-option .names {
		position: absolute;
		display: flex;
		justify-content: space-around;
		user-select: none;
		width: 100%;
		text-transform: lowercase;
		height: 100%;
		margin-left: -2px;
	}
	.stand-option .names p {
		align-self: center;
	}
	.stand-option .names p::first-letter {
		text-transform: capitalize;
	}
	.stand-option .names p:first-child {
		color: #4c12a1;
		font-weight: bold;
	}
	[type='checkbox'] {
		display: none;
	}
	[type='checkbox']:checked + .content .toggle {
		transform: translateX(100%);
	}
	.selectedsize {
		outline: rgb(76, 18, 161) solid 2px;
		padding: 2px;
		outline-offset: 2px;
	}

	.fab {
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		width: 40px;
		height: 40px;
		cursor: pointer;
		background-color: #fff;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
		border-radius: 100px;
		margin: auto;
	}
	@media screen and (max-width: 992px) and (min-width: 768px) {
		.controller_selector_option {
			flex-direction: row;
		}
		.hr-tag {
			display: none;
		}
		.stand-ar-div {
			padding: 1.2rem 0;
		}
	}
</style>

<script lang="ts">
	// @ts-check
	import { createEventDispatcher } from 'svelte';
	import '@google/model-viewer/dist/model-viewer';
	export let src: string;
	export let iosSrc: string;
	export let placement: string;
	export let id:string;

	let firstUserInteractionLogged = false;

	const dispatch = createEventDispatcher();

	function handleCameraChange(e): void {
		if (e.detail.source === 'user-interaction' && !firstUserInteractionLogged) {
			dispatch('firstModelInteraction');
			firstUserInteractionLogged = true;
		}
	}

	function handleArStatusChange(e): void {
		console.log(e);
	}

	function handleLoad(e): void {
		firstUserInteractionLogged = false;
		dispatch('load', e.detail.url);
	}
		
</script>


<model-viewer
	id={id}
	class="w-screen sm:h-screen z-0 absolute view-height"
	alt=""
	{src} 
	ios-src={iosSrc}
	ar
	ar-placement={placement}
	ar-modes="webxr scene-viewer quick-look"
	scene-viewer-browsers="chrome"
	shadow-intensity="1"
	camera-controls
	skybox-image=""
	ar-scale="fixed"
	disable-zoom
	environment-image="neutral"
	exposure="0.7"
	auto-rotate
	
	scale="1"

	on:camera-change={handleCameraChange}
	on:ar-status={handleArStatusChange}
	on:load={handleLoad}
>
</model-viewer>
<style>
	.view-height{
		height:65%;
		width: 100%;
	}
	model-viewer::part(default-ar-button) {
  		background-color: rgb(170, 178, 248);
		display: none;
	}
</style>

